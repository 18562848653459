import React from 'react';
import { graphql } from 'gatsby';
import CraftPageBuilder from '~/components/craft/CraftPageBuilder';
import PageHeaderSpacing from '~/components/common/PageHeaderSpacing';
import Seo from '~/components/common/Seo';
import { Query, Craft_Error404 } from '~/query';

type TemplateError404Props = {
  data: Query;
};

/**
 * Template: Error 404
 *
 * Use this template in correspondence with a craft
 * section with the following properties:
 *
 * Type: single
 * Name: error404
 * URI: 404.html
 */
export default function TemplateError404({ data }: TemplateError404Props) {
  const { pageBuilder, title, seoTitle, seoDescription, seoCanonical } = (data?.craft
    ?.entry ?? {}) as Craft_Error404;

  return (
    <>
      <Seo
        title={seoTitle ?? title}
        description={seoDescription ?? undefined}
        canonical={seoCanonical ?? undefined}
      />
      <PageHeaderSpacing />
      <CraftPageBuilder components={pageBuilder} />
    </>
  );
}

export const query = graphql`
  query QueryError404($id: [Int]!, $site: String!) {
    craft {
      entry(id: $id, site: $site) {
        id
        title
        ... on Craft_Error404 {
          pageBuilder {
            ...CraftPageBuilderQuery
          }

          # SEO
          seoTitle
          seoDescription
        }
      }
    }
  }
`;
